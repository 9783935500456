import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionBox from "../components/section-box"
import SectionBox2 from "../components/section-box2"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

import styled from "styled-components"

import ContactForm from "../components/contact-form"

//Ilustration inports
import ilustration2 from "../images/oblakoder-home-6.png"

//Styled Components Import

import SvgBg1 from "../components/styled/SvgBg"
import GridStyled1 from "../components/styled/Grid1"
import ImageBox1 from "../components/styled/ImageBox"

const ContactUs = () => (
  <Layout>
    <SEO title="Contact" />
    <SvgBg1>
      <GridStyled1>
        <div>
          <ScrollAnimation animateIn="fadeIn" duration={1.8}>
            <h1>
              <span>Contact </span>Us
            </h1>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={150} duration={1.3}>
            <h3>Nephele</h3>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" delay={250} duration={1.4}>
            <SectionBox>
              <p>
                <b>Nephele doo</b>
              </p>
            </SectionBox>

            <SectionBox>
              <p>
                <b>Novi Sad Office</b>
              </p>
            </SectionBox>
            <SectionBox2>
              <p>
                Fruškogorska 41
                <br />
                Novi Sad
                <br />
                Serbia
              </p>
            </SectionBox2>
            <ContactForm />
          </ScrollAnimation>
        </div>
        <ImageBox1>
          <div className="png-img">
            <img src={ilustration2} alt="AWS Well-Architected" />
          </div>
          <div className="res-img">
            <img src={ilustration2} alt="AWS Well-Architected" />
          </div>
        </ImageBox1>
      </GridStyled1>
    </SvgBg1>
  </Layout>
)

export default ContactUs
